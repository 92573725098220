import React, { memo } from 'react';
import Slider from 'react-slick';

// components
import { Image } from 'wikr-core-components';
import ArrowNext from 'components/SliderArrows/ArrowNext';
import ArrowPrev from 'components/SliderArrows/ArrowPrev';

// styles
import styles from './Slider.module.css';
import './SliderCustom.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// types
import { ISimpleSlider } from './types';

function SimpleSlider({ content = [] }: ISimpleSlider) {
    const settings = {
        className: styles.sliderWrapper,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        focusOnSelect: true,
        dots: true,
        arrows: true,
        swipe: false,
        draggable: true,
        infinite: true,
        prevArrow: <ArrowPrev />,
        nextArrow: <ArrowNext />,
        accessibility: true,
        // eslint-disable-next-line react/display-name
        customPaging: () => <span className={styles.blueDot} />,
    };

    return (
        <div className="sliderWrapper">
            <Slider {...settings}>
                {content.map((item, index) => {
                    return (
                        <div key={index}>
                            <Image src={item.img} isForceVisible mb={8} alt="slide image" />
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
}

export default memo(SimpleSlider);
