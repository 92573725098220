export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    return parts.length === 2 ? parts.pop()?.split(';').shift() : '';
};

const fromEntriesPolyfill = (iterable: any) => {
    return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val;

        return obj;
    }, {});
};

export const isEmpty = (value: any) => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const getUrlParams = () => {
    const queryParams = window.location.search;

    if (!isEmpty(queryParams)) {
        const urlSearchParams = new URLSearchParams(queryParams)?.entries();

        if (typeof Object.fromEntries === 'function') {
            return Object.fromEntries(urlSearchParams);
        }

        return fromEntriesPolyfill(urlSearchParams);
    }

    return null;
};

export const updateGlobalLoaderVisibility = (isVisible: boolean) => {
    const globalLoader = document.getElementById('app-loader');

    if (globalLoader) globalLoader.style.display = isVisible ? 'block' : 'none';
};
