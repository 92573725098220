import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

// components
import { Box, Header, Image, Text, Button } from 'wikr-core-components';
import Policy from 'components/Policy';

// styles
import styles from './AtPopUp3Container.module.css';
import commonStyles from 'assets/styles/Common.module.css';

// img
import logo from 'assets/img/fullLogo.webp';
import blackLogo from 'assets/img/iconMb.webp';
import Stars from 'assets/icons/starsSmall.svg';

// types
import { IAtPopUpContainerProps } from 'types/pages/commonTypes';

const cx = classNames.bind(styles);

function AtPopUp3Container({ toNextPage, isOpen, onCancelClick }: IAtPopUpContainerProps) {
    const { t } = useTranslation();

    const wrapperStyles = cx('container', {
        withBanner: isOpen,
    });

    return (
        <>
            <Header left={<Image src={logo} alt="logo" maxWidth={100} isForceVisible />} />
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className={wrapperStyles}>
                <Box
                    borderRadius={8}
                    borderColor="border-default"
                    paddingX={8}
                    paddingTop={12}
                    paddingBottom={14}
                    className={styles.headerBlock}
                >
                    <div className={styles.contentWrapper}>
                        <Image className={styles.logo} src={blackLogo} maxWidth={40} alt="logo" />
                        <div className={styles.textBlock}>
                            <Text bold type="caption" uppercase mb={2}>
                                <Trans i18nKey="atPopUp.title" />
                            </Text>
                            <Text type="caption-small" color="text-secondary-subdued" mb={4}>
                                <Trans i18nKey="atAppShort.headerSubtitle.homeAndGym" />
                            </Text>
                            <div className={styles.ratingBlock}>
                                <Text type="caption-small" text="4.6" />
                                <Stars className={styles.stars} />
                                <Text type="caption-small">
                                    <Trans i18nKey="atApp.ratingsNumber" />
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <Button
                            className={styles.button}
                            size="small"
                            onClick={() => toNextPage('get')}
                            text={t('basics.get')}
                        />
                    </div>
                </Box>
                <div className={commonStyles.fixedPolicy}>
                    <Policy />
                </div>
            </div>
            {isOpen && (
                <div className={styles.banner}>
                    <Text type="large-text" bold color="surface-secondary" center mb={2}>
                        <Trans i18nKey="atPopUp.banner.title" />
                    </Text>
                    <Text type="small-text" color="surface-secondary" center mb={16}>
                        <Trans i18nKey="atPopUp.banner.subtitle" />
                    </Text>
                    <div className={styles.buttonsContainer}>
                        <div className={styles.bannerButton} onClick={onCancelClick}>
                            <Text type="medium-text" center color="safe">
                                <Trans i18nKey="atPopUp.button.cancel" />
                            </Text>
                        </div>
                        <div className={styles.border} />
                        <div className={styles.bannerButton} onClick={() => toNextPage('open_app')}>
                            <Text type="medium-text" center color="safe">
                                <Trans i18nKey="atPopUp.button.open" />
                            </Text>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AtPopUp3Container;
