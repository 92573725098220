import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Main } from 'wikr-core-analytics';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers
import AtPopUp3Container from 'containers/AtPopUp3Container';

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

function AtPopUp3() {
    const location = useLocation();
    const { handleClick } = useAttributionAnalytic();
    const [isOpen, setIsOpen] = useState(true);

    const onCancelClick = () => {
        Main.customData('at_pop_up_3__click', { event_label: 'cancel' });
        setIsOpen(false);
    };

    const toNextPage = (value: string) => {
        updateGlobalLoaderVisibility(true);
        sendAnalyticsWithEventLabel({
            url: location.pathname,
            eventValue: value,
        });

        handleClick();
    };

    return <AtPopUp3Container onCancelClick={onCancelClick} toNextPage={toNextPage} isOpen={isOpen} />;
}

export default AtPopUp3;
