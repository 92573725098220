import AtAge from 'pages/AtAge';
import AtGoalMM2 from 'pages/AtGoalMM2';
import AtApp from 'pages/AtApp';
import AtAppShort from 'pages/AtAppShort';
import AtPopUp3 from 'pages/AtPopUp3';
import AtGoalMM2CPP from 'pages/AtGoalMM2CPP';

export const PUBLIC = {
    AT_AGE: {
        path: '/at-age',
        component: AtAge,
    },
    AT_GOAL_MM2: {
        path: '/at-goal-mm2',
        component: AtGoalMM2,
    },
    AT_APP: {
        path: '/at-app',
        component: AtApp,
    },
    AT_APP_SHORT: {
        path: '/at-app-short',
        component: AtAppShort,
    },
    AT_POP_UP_3: {
        path: '/at-pop-up-3',
        component: AtPopUp3,
    },
    AT_GOAL_MM2CPP: {
        path: '/at-goal-mm2-cpp',
        component: AtGoalMM2CPP,
    },
};

export default {
    PUBLIC,
};
