import { useEffect } from 'react';
import { Main } from 'wikr-core-analytics';

// configs
import config from 'config';

// constants
import { CUSTOM_ATTRIBUTION_LINK_CPP } from 'constants/links';

// helpers
import { getCookie, getUrlParams } from 'helpers/helpers';

export const useAttributionAnalytic = (customLink?: boolean) => {
    const urlParams = JSON.stringify(getUrlParams());

    useEffect(() => {
        Main.initSystems({
            activeSystems: [
                {
                    name: 'pixel',
                    id: config.PIXEL_ID,
                },
                {
                    name: 'amazon',
                    id: config.AMAZON_ID,
                    config: {
                        release_date: config.RELEASE_DATE,
                        streamName: config.AWS_DELIVERY_STREAM_NAME,
                        envMode: config.ENV,
                    },
                },
            ],
        });

        Main.track('attribution');
    }, []);

    const handleClick = () => {
        Main.track('ViewContent', { isPrefix: false });
        Main.track('install_click_attribution', { isPrefix: false });

        const baseUrl = customLink ? CUSTOM_ATTRIBUTION_LINK_CPP : config.ATTRIBUTION_BASE_URL;

        const urlParamsNormalized = JSON?.parse(urlParams);
        const pid = urlParamsNormalized?.utm_source || '';
        const c = urlParamsNormalized?.utm_campaign || '';
        const af_ad_id = urlParamsNormalized?.ad_id || '';
        const af_ad = urlParamsNormalized?.ad_id || '';
        const fbp = getCookie('_fbp') ? getCookie('_fbp') : '';

        const url = new URL(baseUrl);

        url.searchParams.set('pid', pid);
        url.searchParams.set('c', c);
        url.searchParams.set('af_ad_id', af_ad_id);
        url.searchParams.set('af_ad', af_ad);
        if (fbp) {
            url.searchParams.set('fbp', fbp);
        }

        Main.track('install_redirect_attribution', { isPrefix: false });

        setTimeout(() => {
            document.location = url.href;
        }, 1000);
    };

    return { handleClick };
};
