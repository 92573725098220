// images
import man1 from 'assets/img/atAge/man-ages1.webp';
import man2 from 'assets/img/atAge/man-ages2.webp';
import man3 from 'assets/img/atAge/man-ages3.webp';
import man4 from 'assets/img/atAge/man-ages4.webp';

// types
import { IStartAgeConfig } from 'types/pages/commonTypes';

export const getContent = (): IStartAgeConfig => {
    return {
        optionData: [
            {
                img: man1,
                title: '18-25',
                value: '18_25',
                dataLocator: 'ageButton',
            },
            {
                img: man2,
                title: '26-35',
                value: '26_35',
                dataLocator: 'ageButton',
            },
            {
                img: man3,
                title: '36-45',
                value: '36_45',
                dataLocator: 'ageButton',
            },
            {
                img: man4,
                title: '46+',
                value: '46_and_more',
                dataLocator: 'ageButton',
            },
        ],
    };
};
