import React from 'react';

// components
import { Box } from 'wikr-core-components';
import GetApp from 'components/GetApp';

// styles
import styles from './AtAppShortContainer.module.css';

// types
import { IAtAppShortContainerProps } from 'types/pages/commonTypes';

function AtAppShortContainer({ toNextPage }: IAtAppShortContainerProps) {
    return (
        <Box
            paddingX={16}
            paddingTop={192}
            paddingBottom={24}
            backgroundColor="surface-main"
            className={styles.container}
        >
            <GetApp toNextPage={toNextPage} />
        </Box>
    );
}

export default AtAppShortContainer;
