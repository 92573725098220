import React from 'react';
import { Trans } from 'react-i18next';

// components
import { Text } from 'wikr-core-components';

// styles
import styles from './Infromation.module.css';

// img
import Hand from 'assets/icons/hand.svg';

// config
import { getContent } from './config';

function Information() {
    const { info } = getContent();

    return (
        <>
            <Text type="h6" mb={12}>
                <Trans i18nKey="basics.info" />
            </Text>
            {info.map((item) => (
                <>
                    <div className={styles.appInfo}>
                        <Text type="caption" color="text-secondary-subdued">
                            <Trans i18nKey={item.title} />
                        </Text>
                        <Text type="caption" className={styles.infoValue}>
                            <Trans i18nKey={item.value} />
                        </Text>
                    </div>
                    <div className={styles.line} />
                </>
            ))}
            <div className={styles.privacyFooter}>
                <Text type="caption" color="text-secondary-subdued">
                    <Trans i18nKey="basics.privacyTitle" />
                </Text>
                <Hand />
            </div>
        </>
    );
}

export default Information;
