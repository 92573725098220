import React from 'react';
import { Trans } from 'react-i18next';

// components
import { Text, Image } from 'wikr-core-components';

// styles
import styles from './Review.module.css';

// img
import Quotes from 'assets/icons/quotes.svg';
import Stars from 'assets/icons/fullStars.svg';

// config
import { getContent } from './config';

// types
import { IReviewsProps } from 'types/pages/commonTypes';

function Reviews({ reviews }: IReviewsProps) {
    const { ratings } = getContent();

    return (
        <>
            <Text type="h6" mb={24}>
                <Trans i18nKey="atApp.ratingsAndReviews" />
            </Text>
            <div className={styles.ratingsBlock}>
                <div className={styles.rating}>
                    <Text className={styles.bigRating} center bold>
                        4.6
                    </Text>
                    <Text type="medium-text" center bold color="text-secondary-subdued">
                        <Trans i18nKey="atApp.ratingOf" />
                    </Text>
                </div>
                <div>
                    {ratings.map((item) => (
                        <Image key={item.img} isForceVisible src={item.img} alt="reviews" maxWidth={226} mb={6} />
                    ))}
                    <Text className={styles.ratingsCount} type="caption-small" color="text-secondary">
                        <Trans i18nKey="atApp.ratingsNumber" />
                    </Text>
                </div>
            </div>
            {reviews.map((item, index) => (
                <div key={index} className={styles.reviewItem}>
                    <Text type="large-text" color="border-subdued" mb={16}>
                        <Quotes className={styles.quotes} />
                        <Trans i18nKey={item.review} />
                    </Text>
                    <div className={styles.authorReview}>
                        <Stars className={styles.starsIcon} />
                        <Text type="large-text" color="text-secondary-subdued" text={item.author} />
                    </div>
                </div>
            ))}
        </>
    );
}

export default Reviews;
