import React from 'react';
import { useLocation } from 'react-router-dom';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers
import AtAppShortContainer from 'containers/AtAppShortContainer';

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

function AtAppShort() {
    const location = useLocation();
    const { handleClick } = useAttributionAnalytic();

    const toNextPage = () => {
        updateGlobalLoaderVisibility(true);
        sendAnalyticsWithEventLabel({
            url: location.pathname,
            eventValue: 'get_the_app',
        });

        handleClick();
    };

    return <AtAppShortContainer toNextPage={toNextPage} />;
}

export default AtAppShort;
