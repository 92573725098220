import { Main } from 'wikr-core-analytics';

// helpers
import { replaceDashToUnderscore } from 'helpers/transformationUtils';

interface ISendAnalytics {
    url: string;
    eventValue: string;
}

export default ({ url = window.location.href, eventValue: data }: ISendAnalytics) => {
    const decodedURL = decodeURI(url);

    const formattedUrl = decodedURL.split('/').pop();

    const eventValue = data;

    const eventName = `${replaceDashToUnderscore(formattedUrl)}__click`;

    Main.customData(eventName, { event_label: eventValue });
};
