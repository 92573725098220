import React from 'react';
import classNames from 'classnames/bind';

// images
import PrevArrow from '../img/arrowLeft.svg';

// styles
import styles from '../SliderArrows.module.css';

// types
import { IArrowProps } from '../types';

const cx = classNames.bind(styles);

export default function ArrowPrev({ style, onClick }: IArrowProps) {
    const arrowStyles = cx('slickPrev', 'slickArrow');

    return (
        <button className={arrowStyles} style={style} onClick={onClick}>
            <PrevArrow />
        </button>
    );
}
