import React from 'react';
import { useLocation } from 'react-router-dom';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers
import AtGoalMM2Container from 'containers/AtGoalMM2Container';

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

// config
import { getContent } from './config';

function AtGoalMM2CPP() {
    const location = useLocation();
    const { handleClick } = useAttributionAnalytic(true);

    const toNextPage = (value: string) => {
        updateGlobalLoaderVisibility(true);
        sendAnalyticsWithEventLabel({
            url: location.pathname,
            eventValue: value,
        });

        handleClick();
    };

    return <AtGoalMM2Container toNextPage={toNextPage} content={getContent()} />;
}

export default AtGoalMM2CPP;
