import React from 'react';
import { Trans } from 'react-i18next';

// components
import { Button, Image, Text } from 'wikr-core-components';
import Policy from 'components/Policy';

// styles
import styles from './GetApp.module.css';

// img
import Stars from 'assets/icons/starsOrange.svg';
import Smartphone from 'assets/icons/smartphone.svg';
import bigLogo from 'assets/img/iconMb.webp';
import blackLogo from 'assets/img/blackLogo.webp';
import Arrows from 'assets/icons/arrows.svg';

// types
import { IGetAppProps } from 'types/pages/commonTypes';

function GetApp({ toNextPage, isAtAppFull }: IGetAppProps) {
    return (
        <div className={styles.getAppBlock}>
            <div className={styles.logoBlock}>
                <Image src={isAtAppFull ? bigLogo : blackLogo} alt="logo" maxWidth={90} />
                <div className={styles.logoTextBlock}>
                    <Text type="h6">
                        <Trans i18nKey="atApp.headerTitle.planner" />
                    </Text>
                    <Text type="h6" mb={2}>
                        <Trans i18nKey="atApp.headerTitle.mb" />
                    </Text>
                    <Text type="small-text" color="text-secondary-subdued">
                        <Trans
                            i18nKey={
                                isAtAppFull ? 'atApp.headerSubtitle.homeAndGym' : 'atAppShort.headerSubtitle.homeAndGym'
                            }
                        />
                    </Text>
                </div>
            </div>
            <div className={styles.headerRatingsBlock}>
                <div className={styles.starsRating}>
                    <Text type="large-text" bold text="4.6" />
                    <Stars className={styles.stars} />
                </div>
                <Text type="caption" color="text-secondary-subdued">
                    <Trans i18nKey="atApp.ratingsNumber" />
                </Text>
            </div>
            <Button mb={20} onClick={toNextPage} className={styles.getApp} withPulsating>
                <Text type="medium-button">
                    <Trans i18nKey="atApp.getApp" />
                </Text>
                <Arrows className={styles.arrows} />
            </Button>
            {isAtAppFull && (
                <div className={styles.availableBlock}>
                    <Smartphone className={styles.smartphone} />
                    <Text type="small-text" color="text-secondary">
                        <Trans i18nKey="atApp.appStoreAvailable" />
                    </Text>
                </div>
            )}
            <Policy />
        </div>
    );
}

export default GetApp;
