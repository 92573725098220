import React from 'react';
import { Trans } from 'react-i18next';

// components
import { Text } from 'wikr-core-components';

// constants
import { PRIVACY_POLICY } from 'constants/policy';

// styles
import commonStyles from 'assets/styles/Common.module.css';

function Policy() {
    return (
        <Text type="caption" center color="text-secondary-subdued">
            <Trans
                i18nKey="basics.privacy"
                components={{
                    1: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                            className={commonStyles.colorWhite}
                            href={PRIVACY_POLICY}
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                    ),
                }}
            />
        </Text>
    );
}

export default Policy;
