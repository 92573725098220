import { useTranslation } from 'react-i18next';

// images
import man1 from 'assets/img/atGoal/att_goal_1.webp';
import man2 from 'assets/img/atGoal/att_goal_2.webp';
import man3 from 'assets/img/atGoal/att_goal_3.webp';

// types
import { IAtGoalConfig } from 'types/pages/commonTypes';

export const getContent = (): IAtGoalConfig => {
    const { t } = useTranslation();

    return {
        optionData: [
            {
                rightImage: man1,
                value: 'lose_weight',
                title: t('atGoalMM.loseWeight'),
                mb: 12,
                dataLocator: 'AtOption',
            },
            {
                rightImage: man2,
                value: 'gain_muscle',
                title: t('atGoalMM.gainMuscle'),
                mb: 12,
                dataLocator: 'AtOption',
            },
            {
                rightImage: man3,
                value: 'improve_stamina',
                title: t('atGoalMM.improveStamina'),
                mb: 12,
                dataLocator: 'AtOption',
            },
        ],
        title: t('atGoalMM.title'),
        subtitle: t('atGoalMM.subtitle'),
    };
};
