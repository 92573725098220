import { useTranslation } from 'react-i18next';

export const getContent = () => {
    const { t } = useTranslation();

    return {
        info: [
            {
                title: t('basics.provider'),
                value: t('Welltech Apps Limited'),
            },
            {
                title: t('basics.size'),
                value: t('atApp.infoBlock.sizeMb'),
            },
            {
                title: t('basics.category'),
                value: t('basics.healthAndFitness'),
            },
            {
                title: t('basics.compatibility'),
                value: t('basics.ios'),
            },
            {
                title: t('basics.lang'),
                value: t('basics.langList'),
            },
        ],
    };
};
