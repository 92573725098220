import React from 'react';
import { useLocation } from 'react-router-dom';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers
import AtAppContainer from 'containers/AtAppContainer';

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

// config
import { getContent } from './config';

function AtApp() {
    const location = useLocation();
    const { handleClick } = useAttributionAnalytic();
    const { reviews, sliderData } = getContent();

    const toNextPage = () => {
        updateGlobalLoaderVisibility(true);
        sendAnalyticsWithEventLabel({
            url: location.pathname,
            eventValue: 'get_the_app',
        });

        handleClick();
    };

    return <AtAppContainer toNextPage={toNextPage} reviews={reviews} sliderData={sliderData} />;
}

export default AtApp;
