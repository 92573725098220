import React from 'react';

// components
import { Image, Text } from 'wikr-core-components';

// img
import Next from 'assets/icons/next.svg';

// styles
import styles from './StartPageCard.module.css';

// types
import { ICard } from 'types/pages/commonTypes';

function StartPageCard({ dataLocator = 'startOption', onClick, img, value, title, boxClassName, type }: ICard) {
    return (
        <button onClick={(e) => onClick(e, value)} data-locator={dataLocator} className={boxClassName}>
            <div className={styles.imageWrapper}>
                <Image src={img} alt="body" isForceVisible className={styles.smallImage} />
            </div>
            <div className={styles.buttonBox}>
                <Text type={type} color="on-primary" bold className={styles.buttonText}>
                    {title}
                    <Next className={styles.nextIcon} />
                </Text>
            </div>
        </button>
    );
}

export default StartPageCard;
