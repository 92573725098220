import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

// libComponents
import { OptionBox, Text, Header, Image } from 'wikr-core-components';

// constants
import { PRIVACY_POLICY } from 'constants/policy';

// images
import Stars from 'assets/img/stars.svg';
import logo from 'assets/img/fullLogo.webp';
import appStore from 'assets/img/AppStore.webp';
import Apple from 'assets/icons/apple.svg';

// styles
import styles from './AtGoalMM2Container.module.css';
import commonStyles from 'assets/styles/Common.module.css';

// types
import { IAtGoalMMContainer } from 'types/pages/commonTypes';

function AtGoalMM2Container({ toNextPage, content }: IAtGoalMMContainer) {
    const [selectedValue, setSelectedValue] = useState('');
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;

            return;
        }

        setTimeout(() => selectedValue && toNextPage(selectedValue), 150);
    }, [selectedValue]);

    const optionBoxHandler = (value: string) => {
        setSelectedValue(value);
    };

    return (
        <>
            <Header left={<Image src={logo} alt="logo" maxWidth={100} />} />
            <div className={styles.container}>
                <Text type="h4" center mb={content.subtitle ? 12 : 24} color="on-primary">
                    <Trans i18nKey={content.title} />
                </Text>
                <Text type="medium-text" center mb={16} color="on-primary">
                    <Trans i18nKey={content.subtitle} />
                </Text>
                <OptionBox onClick={optionBoxHandler} optionData={content.optionData} />
                <div className={styles.rateBlock}>
                    <div className={styles.rate}>
                        <Stars />
                        <Apple />
                        <Text type="small-text" className={styles.downloads}>
                            <Trans i18nKey="atGoalMM.downloadsCount" values={{ downloads: 55 }} />
                        </Text>
                    </div>
                    <button
                        onClick={() => toNextPage('AppleStoreBtn')}
                        className={styles.LinkWrapper}
                        data-locator="AppleStoreBtn"
                    >
                        <Image src={appStore} alt="app store" maxWidth={121} center />
                    </button>
                </div>
                <div className={commonStyles.fixedPolicy}>
                    <Text type="caption" center color="text-secondary-subdued">
                        <Trans
                            i18nKey="basics.privacy"
                            components={{
                                1: (
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a
                                        className={commonStyles.colorWhite}
                                        href={PRIVACY_POLICY}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />
                                ),
                            }}
                        />
                    </Text>
                </div>
            </div>
        </>
    );
}

export default AtGoalMM2Container;
