import React from 'react';
import { Trans } from 'react-i18next';

// styles
import styles from './AtAgeContainer.module.css';
import commonStyles from 'assets/styles/Common.module.css';

// components
import { Text, Header, Image, Box } from 'wikr-core-components';
import StartPageCard from 'components/StartPageCard';

// constants
import { PRIVACY_POLICY } from 'constants/policy';

// img
import logo from 'assets/img/fullLogo.webp';

// types
import { IAtAgeContainerProps } from 'types/pages/commonTypes';

function AtAgeContainer({ toNextPage, optionData }: IAtAgeContainerProps) {
    const setActive = (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
        e.currentTarget.setAttribute('active', value);
    };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
        setActive(e, value);

        toNextPage(value);
    };

    return (
        <>
            <Header left={<Image src={logo} alt="logo" maxWidth={100} isForceVisible />} />
            <Box paddingX={16} paddingTop={24} paddingBottom={24} backgroundColor="surface-main">
                <Text type="h4" center mb={16}>
                    <Trans i18nKey="atAge.title" />
                </Text>
                <div className={styles.optionBox}>
                    {optionData.map((card, index) => (
                        <StartPageCard
                            onClick={handleClick}
                            key={index}
                            title={card.title}
                            img={card.img}
                            value={card.value}
                            dataLocator={card.dataLocator}
                            boxClassName={styles.option}
                            type="medium-button"
                        />
                    ))}
                </div>
                <div className={commonStyles.fixedPolicy}>
                    <Text type="caption" center color="text-secondary-subdued">
                        <Trans
                            i18nKey="basics.privacy"
                            components={{
                                1: (
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a
                                        className={commonStyles.colorWhite}
                                        href={PRIVACY_POLICY}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />
                                ),
                            }}
                        />
                    </Text>
                </div>
            </Box>
        </>
    );
}

export default AtAgeContainer;
