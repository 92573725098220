import React from 'react';
import { Trans } from 'react-i18next';

// styles
import styles from './AtAppContainer.module.css';

// components
import { Text, Box } from 'wikr-core-components';
import Slider from 'components/Slider';

// subComponents
import GetApp from 'components/GetApp';
import Reviews from 'components/Reviews';
import Information from 'components/Information';

// types
import { IAtAppContainerProps } from 'types/pages/commonTypes';

function AtAppContainer({ toNextPage, reviews, sliderData }: IAtAppContainerProps) {
    return (
        <Box paddingX={16} paddingTop={24} paddingBottom={24} backgroundColor="surface-main">
            <GetApp toNextPage={toNextPage} isAtAppFull />
            <div className={styles.line} />
            <Text type="h5" center mb={16}>
                <Trans i18nKey="atApp.whatsInside" />
            </Text>
            <Slider content={sliderData} />
            <Text type="small-text" mb={22} className={styles.firstDisclaimer}>
                <Trans i18nKey="atApp.disclaimerFirst" />
            </Text>
            <Text type="small-text" mb={24}>
                <Trans i18nKey="atApp.disclaimerSecond" />
            </Text>
            <div className={styles.line} />
            <Reviews reviews={reviews} />
            <div className={styles.line} />
            <Information />
        </Box>
    );
}

export default AtAppContainer;
