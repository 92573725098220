import { useTranslation } from 'react-i18next';

// types
import { IAtAppConfig } from 'types/pages/commonTypes';

// img
import firstSlide from './img/slide1.webp';
import secondSlide from './img/slide2.webp';
import thirdSlide from './img/slide3.webp';
import fourthSlide from './img/slide4.webp';

export const getContent = (): IAtAppConfig => {
    const { t } = useTranslation();

    return {
        reviews: [
            {
                review: t('atApp.reviews.firstReview'),
                author: 'Art',
            },
            {
                review: t('atApp.reviews.secondReview'),
                author: 'Andr3w',
            },
            {
                review: t('atApp.reviews.thirdReview'),
                author: 'Art61',
            },
        ],
        sliderData: [
            {
                img: firstSlide,
            },
            {
                img: secondSlide,
            },
            {
                img: thirdSlide,
            },
            {
                img: fourthSlide,
            },
        ],
    };
};
