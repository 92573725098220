// img
import first from './img/5.webp';
import second from './img/4.webp';
import third from './img/3.webp';
import fourth from './img/2.webp';
import fifth from './img/1.webp';

export const getContent = () => {
    return {
        ratings: [
            {
                img: first,
            },
            {
                img: second,
            },
            {
                img: third,
            },
            {
                img: fourth,
            },
            {
                img: fifth,
            },
        ],
    };
};
